import styled from 'styled-components';
import { Text14Regular } from '../../Text/Text.styles';
import { theme } from '../../../../styles/constants';

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.8rem;
  opacity: ${props => (props.disabled ? 0.8 : 1)};

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const StyledInput = styled.input`
  font-size: 1.5rem;
  letter-spacing: -0.15px;
  line-height: normal;
  font-weight: 400;
  color: ${theme.colors.textColor};
  background: ${theme.colors.white};
  height: 4.8rem;
  width: 100%;
  border-radius: 16px;
  border: 1px solid #c5bef4;
  padding: 1.4rem 1.6rem;
  box-shadow: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }

  &::placeholder {
    color: #54329e;
    font-weight: 300;
  }
`;

export const LabelText = styled(Text14Regular)`
  margin-bottom: 0.6rem;
  margin-left: 0.2rem;
  text-transform: capitalize;
`;
