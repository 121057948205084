import styled from 'styled-components';
import { Button } from '../../utils/Button/Button';
import { Text14Light, Text14Regular } from '../../utils/Text/Text.styles';

export const Form = styled.form`
  @media only screen and (min-width: 768px) {
    padding-bottom: 3rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 23rem;
  margin-inline: auto;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 1.2rem;
  margin-bottom: 2.4rem;
`;

export const LabelText = styled(Text14Regular)`
  margin-bottom: 0.6rem;
  margin-left: 0.2rem;
  text-transform: capitalize;
`;

export const NoteText = styled(Text14Light)`
  opacity: 0.5;
  margin-bottom: 3rem;
  text-align: justify;
`;
