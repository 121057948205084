import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 2.4rem 1.6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2.4rem;
  flex-grow: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const TitleRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;
