import styled from 'styled-components';
import { Text16Regular } from '../../utils/Text/Text.styles';
import { Button, TransparentButton } from '../../utils/Button/Button';

export const ConfirmationModalContainer = styled.div`
  max-width: 60rem;
  padding: 2.4rem;
`;

export const Footer = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: space-around;
  margin-top: 4.6rem;
`;

export const CancelButton = styled(TransparentButton)`
  width: 30%;
  font-weight: 500;
`;

export const ConfirmButton = styled(Button)`
  width: 60%;
  font-weight: 500;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 2.4rem;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Subtitle = styled(Text16Regular)`
  text-align: center;
  margin-top: 0.5rem;
`;
