import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { selectInfluencerFactActionsPending } from '../../../store/slices/influencerFact/slice';
import {
  agencyCreateInfluencerFacts,
  agencyEditInfluencerFact,
  createInfluencerFacts,
  editInfluencerFact,
} from '../../../store/slices/influencerFact/asyncThunks';
import { notifyError, notifyInfo } from '../../../utils/notify';
import BaseModal from '../utils/BaseModal/BaseModal';
import { ButtonWrapper, CancelButton, Form, SubmitButton } from './NewInfluencerFactFormModal.styles';
import Input from '../../utils/inputs/Input/Input';

export const createInfluencerFactsFormSchema = yup
  .object({ text: yup.string().trim().required('Text is required') })
  .required();

const NewInfluencerFactFormModal = ({ modalRef, influencerId, selectedFact, setSelectedFact }) => {
  const dispatch = useDispatch();
  const { createInfluencerFacts: createInfluencerFactsPending, editInfluencerFact: editInfluencerFactPending } =
    useSelector(selectInfluencerFactActionsPending);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      text: '',
    },
    delayError: 300,
    resolver: yupResolver(createInfluencerFactsFormSchema),
  });

  useEffect(() => {
    if (selectedFact) {
      setValue('text', selectedFact.text);
    }
  }, [selectedFact, setValue]);

  const onSubmit = data => {
    let { text } = data;
  
    // Check if text contains "- " and "\n" indicating multiple facts
    const facts = text.includes('-') && text.includes('\n') 
      ? text.split('-').map(fact => fact.trim()).filter(fact => fact) // Split and trim each fact
      : [text.trim()]; // Single fact as an array
  

    if (selectedFact) {
      if (influencerId) {
        dispatch(agencyEditInfluencerFact({ text, influencerFactId: selectedFact.id }))
          .unwrap()
          .then(() => {
            reset();
            modalRef.current.hide();
            notifyInfo('Fact updated successfully!');
          })
          .catch(err => {
            notifyError(err.message);
          });
      } else {
        dispatch(editInfluencerFact({ text, influencerFactId: selectedFact.id }))
          .unwrap()
          .then(() => {
            reset();
            modalRef.current.hide();
            notifyInfo('Fact updated successfully!');
          })
          .catch(err => {
            notifyError(err.message);
          });
      }
    } else {
      if (influencerId) {
        dispatch(agencyCreateInfluencerFacts({ facts, influencerId }))
          .unwrap()
          .then(() => {
            reset();
            modalRef.current.hide();
            notifyInfo('Fact created successfully!');
          })
          .catch(err => {
            notifyError(err.message);
          });
      } else {
        dispatch(createInfluencerFacts({ facts }))
          .unwrap()
          .then(() => {
            reset();
            modalRef.current.hide();
            notifyInfo('Fact created successfully!');
          })
          .catch(err => {
            notifyError(err.message);
          });
      }
    }
  };

  return (
    <BaseModal
      title={selectedFact ? 'Edit Fact' : 'Add New Fact'}
      ref={modalRef}
      onClose={() => {
        reset();
        setSelectedFact(null);
        modalRef.current.hide();
      }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Description"
          placeholder="Enter description"
          {...register('text')}
          error={errors.text?.message}
          type="textarea"
        />
        <ButtonWrapper>
          <CancelButton
            title="Cancel"
            onClick={() => {
              reset();
              setSelectedFact(null);
              modalRef.current.hide();
            }}
            type="button"
          />
          <SubmitButton
            type="submit"
            title="Save"
            isLoading={createInfluencerFactsPending || editInfluencerFactPending}
          />
        </ButtonWrapper>
      </Form>
    </BaseModal>
  );
};

export default NewInfluencerFactFormModal;
