import styled from 'styled-components';
import { ReactComponent as CloseIconSvg } from '../../../assets/icons/close.svg';
import { Button, TransparentButton } from '../../utils/Button/Button';

export const ModalStyle = styled.div`
  position: relative;
  width: 100%;
  max-width: 80rem;
  height: 80%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: none;
  border-radius: 17px;
  border: 1px solid #c5bef4;
  background: #fff;
  padding: 1.6rem;
  margin-inline: 1rem;
`;

export const OverlayStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3500;
  background: rgba(22, 19, 32, 0.5);
`;

export const Header = styled.div`
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseIcon = styled(CloseIconSvg)`
  cursor: pointer;
`;

export const Content = styled.div`
  position: relative;
  height: calc(100% - 19rem);
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CroppedImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const Footer = styled.div`
  height: 4.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftButton = styled(TransparentButton)`
  width: 12rem;
  justify-content: flex-start;
`;

export const RightButton = styled(Button)`
  width: 12rem;
  height: 4.3rem;
  font-size: 1.5rem;
`;
