import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { EARNINGS_TYPE, VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { theme } from '../../../styles/constants';
import { Container } from './HomeBarChart.styles';
import { useSelector } from 'react-redux';
import { selectEarningType } from '../../../store/slices/user/slice';

const HomeBarChart = ({ dailyAnalytics }) => {
  const earningType = useSelector(selectEarningType);

  const data = useMemo(() => {
    const days = Object.keys(dailyAnalytics);

    const multiplier = earningType.value === EARNINGS_TYPE.NET ? 1 : 2;

    return days.map(day => {
      const date = parseISO(day);
      const dayOfWeek = format(date, 'd MMM yy');

      const photos = dailyAnalytics[day].photo.earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier;
      const videos = dailyAnalytics[day].video.earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier;
      const bundles = dailyAnalytics[day].bundle.earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier;
      const subscription = dailyAnalytics[day].subscription.earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier;
      const other = dailyAnalytics[day].other.earnings * VALUE_OF_ONE_STAR_IN_USD * multiplier;
      return {
        name: dayOfWeek,
        data: [photos, videos, bundles, subscription, other],
      };
    });
  }, [dailyAnalytics, earningType]);

  const colors = ['#DDCEFF', '#AD88EF', '#7E4FD0', '#572E9F', '#311D5C'];

  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Lexend',
      },
    },
    title: {
      text: '',
    },
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: theme.colors.textColor,
        fontSize: 12,
      },
    },
    xAxis: {
      categories: data.map(item => item.name),
      min: 0,
      max: data.length > 6 ? 6 : data.length - 1,
      scrollbar: {
        enabled: true,
        height: 10,
        trackBorderColor: '#F0EBFF',
        barBorderColor: '#F0EBFF',
        barBackgroundColor: '#F0EBFF',
        trackBackgroundColor: 'transparent',
      },
      labels: {
        style: {
          color: theme.colors.textColor,
          fontSize: 11,
        },
      },
      gridLineColor: '#E0E0E0',
      gridLineDashStyle: 'Dot',
      gridLineWidth: 1,
    },
    yAxis: {
      title: {
        text: '',
      },
      gridLineColor: '#E0E0E0',
      gridLineDashStyle: 'Dot',
      labels: {
        formatter: function ({ value }) {
          return `$${value}`;
        },
        style: {
          color: theme.colors.textColor,
          fontSize: 12,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
      series: {
        borderWidth: 0,
        borderRadius: 5,
        groupPadding: 0,
      },
    },
    colors: colors,
    tooltip: {
      valueDecimals: 2,
      shared: true,
      style: {
        fontSize: 12,
      },
      formatter: function () {
        const total = this.points.reduce((sum, point) => sum + point.y, 0);
        let tooltipText = `<b >${this.x}</b><br/><br/>`;

        this.points.forEach(point => {
          const markerStyle = `<span style="color:${point.color}">\u25CF</span>`; // Dot with series color
          tooltipText += `${markerStyle} ${point.series.name}: ${point.y.toFixed(2)}<br/>`;
        });

        tooltipText += `<br/><b>Total: ${total.toFixed(2)}</b>`;
        return tooltipText;
      },
    },
    series: [
      {
        name: 'Photos',
        data: data.map(item => item.data[0]),
        maxPointWidth: 80,
      },
      {
        name: 'Videos',
        data: data.map(item => item.data[1]),
        maxPointWidth: 80,
      },
      {
        name: 'Bundles',
        data: data.map(item => item.data[2]),
        maxPointWidth: 80,
      },
      {
        name: 'Subscriptions',
        data: data.map(item => item.data[3]),
        maxPointWidth: 80,
      },
      {
        name: 'Other',
        data: data.map(item => item.data[4]),
        maxPointWidth: 80,
      },
    ],
  };

  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  );
};

export default HomeBarChart;
