import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 2.4rem 1.6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2.4rem;
  flex-grow: 1;
`;

export const ChartContainer = styled.div`
  border-radius: 17px;
  border: 1px solid #f2f2f2;
  box-shadow: 4px 5px 2px 0px rgba(0, 0, 0, 0), 2px 3px 2px 0px rgba(0, 0, 0, 0.01);
  padding: 2rem;
  min-height: 51rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const ChartContent = styled.div`
  display: flex;
  gap: 3rem;
  width: 100%;
  height: 100%;

  .recharts-default-legend {
    display: flex;
    flex-direction: row-reverse;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Line = styled.div`
  width: 1px;
  background: #f2f2f2;
  height: 100%;

  @media only screen and (max-width: 600px) {
    height: 1px;
    width: 100%;
  }
`;

export const StatisticContainer = styled.div`
  display: flex;
  gap: 1.6rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const TitleRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media only screen and (max-width: 768px) {
    gap: 1rem;
  }
`;
