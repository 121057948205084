import React, { useCallback, useMemo } from 'react';
import {
  AccordionItemContent,
  AccordionItemFooter,
  DollarSign,
  EarningsByMonthContainer,
  FooterText,
  FooterTextContainer,
  ItemCard,
  ItemContentContainer,
  LeftContent,
  RightContent,
} from './InfluencerEarningsByMonth.styles';
import Spinner from '../Spinner/Spinner';
import Accordion from '../utils/Accordion/Accordion';
import { format, parse } from 'date-fns';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../utils/constants';
import { formatPrice } from '../../utils/util';
import { Text14Regular, Text15Regular, Text18Bold } from '../utils/Text/Text.styles';
import { ReactComponent as ImageIcon } from '../../assets/icons/image.svg';
import { ReactComponent as VideoRecorderIcon } from '../../assets/icons/video-recorder.svg';
import { ReactComponent as SaleIcon } from '../../assets/icons/sale.svg';
import { ReactComponent as FilmIcon } from '../../assets/icons/film.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';

const ITEM_CONTENT = {
  photo: { name: 'Photos', icon: <ImageIcon /> },
  video: { name: 'Videos', icon: <VideoRecorderIcon /> },
  bundle: { name: 'Bundles', icon: <FilmIcon /> },
  subscription: { name: 'Subscriptions', icon: <StarIcon /> },
  other: { name: 'Other', icon: <SaleIcon /> },
};

const InfluencerEarningsByMonth = ({ monthlyInfluencerEarnings, pending }) => {
  const renderAccordionItemContent = useCallback((data, totalEarnings) => {
    return (
      <AccordionItemContent>
        <ItemContentContainer>
          {Object.entries(data).map(([key, value]) => {
            return (
              <ItemCard key={key}>
                <LeftContent>
                  {ITEM_CONTENT[key].icon}
                  <Text15Regular>{ITEM_CONTENT[key].name}</Text15Regular>
                </LeftContent>
                <RightContent>
                  <FooterTextContainer>
                    <FooterText>Net: </FooterText>
                    <DollarSign>$</DollarSign>
                    <Text14Regular>{formatPrice(value.earnings * VALUE_OF_ONE_STAR_IN_USD)}</Text14Regular>
                  </FooterTextContainer>
                  <FooterTextContainer>
                    <FooterText>Gross: </FooterText>
                    <DollarSign>$</DollarSign>
                    <Text18Bold>{formatPrice(value.earnings * VALUE_OF_ONE_STAR_IN_USD * 2)}</Text18Bold>
                  </FooterTextContainer>
                </RightContent>
              </ItemCard>
            );
          })}
        </ItemContentContainer>
        <AccordionItemFooter>
          <FooterTextContainer>
            <FooterText>Net: </FooterText>
            <DollarSign>$</DollarSign>
            <Text14Regular>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD)}</Text14Regular>
          </FooterTextContainer>
          <FooterTextContainer>
            <FooterText>Gross: </FooterText>
            <DollarSign>$</DollarSign>
            <Text18Bold>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD * 2)}</Text18Bold>
          </FooterTextContainer>
        </AccordionItemFooter>
      </AccordionItemContent>
    );
  }, []);

  const data = useMemo(() => {
    if (monthlyInfluencerEarnings) {
      return Object.entries(monthlyInfluencerEarnings).map(([key, value]) => {
        const parsedDate = parse(key, 'yyyy-M', new Date());
        const monthName = format(parsedDate, 'MMMM');
        const totalEarnings = Object.values(value).reduce((acc, curr) => acc + curr.earnings, 0);

        return {
          title: monthName,
          value: (
            <AccordionItemFooter>
              <FooterTextContainer>
                <FooterText>Net: </FooterText>
                <DollarSign>$</DollarSign>
                <Text14Regular>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD)}</Text14Regular>
              </FooterTextContainer>
              <FooterTextContainer>
                <FooterText>Gross: </FooterText>
                <DollarSign>$</DollarSign>
                <Text18Bold>{formatPrice(totalEarnings * VALUE_OF_ONE_STAR_IN_USD * 2)}</Text18Bold>
              </FooterTextContainer>
            </AccordionItemFooter>
          ),
          content: renderAccordionItemContent(value, totalEarnings),
        };
      });
    }

    return [];
  }, [monthlyInfluencerEarnings, renderAccordionItemContent]);

  return (
    <EarningsByMonthContainer>
      {pending ? <Spinner width={150} height={150} /> : <Accordion items={data} contentHeight={380} />}
    </EarningsByMonthContainer>
  );
};

export default InfluencerEarningsByMonth;
