import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/d/influencer-facts';

class InfluencerFactService {
  fetchInfluencerFacts = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total, perPage: perPageResponse } = response.data.data;
      return { data, maxReached: total <= +page * +perPageResponse, page };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencer facts.');
    }
  };

  fetchAgencyInfluencerFacts = async (page, search, influencerId) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${influencerId}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total, perPage: perPageResponse } = response.data.data;
      return { data, maxReached: total <= +page * +perPageResponse, page };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencer facts.');
    }
  };

  createInfluencerFacts = async facts => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}`, { facts });
      throwErrorIfWrongStatus(response, 200);
      const { influencerFacts } = response.data.data;
      return { influencerFacts };
    } catch (err) {
      throw handleError(err, 'Error occurred while creating influencer facts.');
    }
  };

  agencyCreateInfluencerFacts = async (facts, influencerId) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/agency`, { facts, influencerId });
      throwErrorIfWrongStatus(response, 200);
      const { influencerFacts } = response.data.data;
      return { influencerFacts };
    } catch (err) {
      throw handleError(err, 'Error occurred while creating influencer facts.');
    }
  };

  deleteInfluencerFact = async influencerFactId => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${influencerFactId}`);
      throwErrorIfWrongStatus(response, 200);
      return { influencerFactId };
    } catch (err) {
      throw handleError(err, 'Error occurred while deleting influencer fact.');
    }
  };

  deleteAgencyInfluencerFact = async influencerFactId => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${influencerFactId}/agency`);
      throwErrorIfWrongStatus(response, 200);
      return { influencerFactId };
    } catch (err) {
      throw handleError(err, 'Error occurred while deleting influencer fact.');
    }
  };

  editInfluencerFact = async (influencerFactId, text) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/${influencerFactId}`, { text });
      throwErrorIfWrongStatus(response, 200);
      const { influencerFact } = response.data.data;
      return { influencerFact };
    } catch (err) {
      throw handleError(err, 'Error occurred while updating influencer fact.');
    }
  };

  agencyEditInfluencerFact = async (influencerFactId, text) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/${influencerFactId}/agency`, { text });
      throwErrorIfWrongStatus(response, 200);
      const { influencerFact } = response.data.data;
      return { influencerFact };
    } catch (err) {
      throw handleError(err, 'Error occurred while updating influencer fact.');
    }
  };
}

export default new InfluencerFactService();
