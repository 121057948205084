import styled from 'styled-components';
import { Button } from '../../utils/Button/Button';

export const Form = styled.form`
  @media only screen and (min-width: 768px) {
    padding-bottom: 3rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 23rem;
  margin-inline: auto;
`;
