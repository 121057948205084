import styled from 'styled-components';

export const ModalStyle = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 1.6rem;
  outline: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  max-height: 26rem;
  margin-bottom: 16rem;

  @media only screen and (max-width: 600px) {
    max-height: unset;
    width: 90%;
  }
`;

export const OverlayStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7500;
  background: rgba(22, 19, 32, 0.5);
`;
