import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import {
  agencyCreateInfluencerFactsBuilder,
  agencyEditInfluencerFactBuilder,
  createInfluencerFactsBuilder,
  deleteAgencyInfluencerFactBuilder,
  deleteInfluencerFactBuilder,
  editInfluencerFactBuilder,
  fetchAgencyInfluencerFactsBuilder,
  fetchInfluencerFactsBuilder,
} from './builder';

const INIT_STATE = {
  fetchInfluencerFactsInfo: {
    data: [],
    maxReached: false,
    pending: false,
    fetchMorePending: false,
  },
  pending: {
    createInfluencerFacts: false,
    deleteInfluencerFact: false,
    editInfluencerFact: false,
  },
};

export const influencerFactSlice = createSlice({
  name: 'influencerFacts',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    fetchInfluencerFactsBuilder(builder);
    fetchAgencyInfluencerFactsBuilder(builder);
    createInfluencerFactsBuilder(builder);
    deleteInfluencerFactBuilder(builder);
    editInfluencerFactBuilder(builder);
    deleteAgencyInfluencerFactBuilder(builder);
    agencyEditInfluencerFactBuilder(builder);
    agencyCreateInfluencerFactsBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchInfluencerFactsInfo = state => state.influencerFactInfo.fetchInfluencerFactsInfo;
export const selectInfluencerFactActionsPending = state => state.influencerFactInfo.pending;

export default influencerFactSlice.reducer;
