import styled from 'styled-components';
import { Button } from '../../../components/utils/Button/Button';

export const Container = styled.div`
  height: 100%;
`;

export const StyledButton = styled(Button)`
  width: unset;
  height: 3.4rem;
  font-size: 1.4rem;
  padding-inline: 1.6rem;
  border-radius: 12px;
`;

export const Content = styled.div`
  flex-grow: 1;
  width: 42rem;
  margin-inline: auto;
  padding-top: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1050px) {
    width: 100%;
    padding-inline: 1.6rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f5f5fd;
`;
