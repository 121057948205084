import styled from 'styled-components';
import { Text14Regular } from '../../../components/utils/Text/Text.styles';

export const PageContainer = styled.div`
  padding: 2.4rem 1.6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const BackContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavigationContainer = styled.div`
  border-radius: 10px;
  background: #f5f5fd;
  padding: 0.4rem;
  margin: 1.6rem 0;
  width: fit-content;
  display: flex;
`;

export const NavigatorItem = styled.div`
  a {
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    align-items: center;
    padding: 0.4rem 0.8rem;
    border: 1px solid transparent;
    border-radius: 8px;
    opacity: 0.5;
  }

  a.active {
    opacity: 1;
    border-color: #eeecfb;
    background: #fff;
    box-shadow: 4px 5px 2px 0px rgba(0, 0, 0, 0), 2px 3px 2px 0px rgba(0, 0, 0, 0.01);
  }
`;

export const NavLinkText = styled(Text14Regular)``;
