import styled from 'styled-components';
import { theme } from '../../../styles/constants';

export const StyledBasicButton = styled.button`
  position: relative;
  width: 100%;
  height: 4.6rem;
  padding: ${props => (props.$isLoading ? '0 3.4rem' : '1.2rem')};
  border-radius: 16px;
  color: ${theme.colors.white};
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.42px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    border: none;
    background: #f5f5f5;
    font-weight: 500;
    color: #442782;
  }
`;

export const StyledButton = styled(StyledBasicButton)`
  border: 1px solid #8e5fe6;
  background: linear-gradient(178deg, #9369eb 0.66%, #5e3baa 97.7%);
`;

export const StyledTransparentButton = styled(StyledBasicButton)`
  background-color: transparent;
  color: ${theme.colors.textColor};
`;

export const ButtonIconWrapper = styled.div`
  height: 4.4rem;
  display: flex;
  align-items: center;
`;

export const ButtonLoader = styled.img`
  height: 100%;
  aspect-ratio: 1 / 1;
`;
